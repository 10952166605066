<template>
  <div class="d-none" v-if="modalShow">
    <b-modal
      v-model="modalShow"
      class="confirmModal"
      ref="confirmModal"
      hide-header
      hide-footer
      no-close-on-backdrop
      centered
      body-class="p-4"
    >
      <div class="text-center mt-3">
        <span class="text-header" v-if="header">{{ header }}</span>
        <p class="text-text" v-if="text">{{ text }}</p>
        <p class="text-sub" v-if="subText">{{ subText }}</p>
      </div>
      <div class="text-center mt-3">
        <b-button
          variant="outline-secondary"
          pill
          class="mr-2 px-4"
          @click="handleCloseModal"
        >
          {{ button.false.msg }}</b-button
        >
        <b-button variant="primary" pill class="px-4" @click="handleOkModal">
          {{ button.true.msg }}</b-button
        >
      </div>
      <div class="text-center mt-2"></div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      required: false,
      type: String
    },
    text: {
      required: false,
      type: String
    },
    subText: {
      required: false,
      type: String
    },
    button: {
      required: false,
      type: Object,
      default: () => {
        return {
          true: {
            class: ["btn-success"],
            msg: "OK"
          },
          false: {
            class: ["btn-danger"],
            msg: "Cancle"
          }
        };
      }
    }
  },
  data() {
    return {
      modalShow: false
    };
  },
  methods: {
    show() {
      this.modalShow = true;
    },
    close() {
      this.modalShow = false;
    },
    handleOkModal() {
      this.$emit("handler", true);
      this.modalShow = false;
    },
    handleCloseModal() {
      this.$emit("handler", false);
      this.modalShow = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.confirmModal {
  width: auto;
}
.modal-header {
  border: 0 !important;
  padding: 0;
}
.text-header {
  font-size: 18px;
}
.text-text {
  color: #4a4a4a;
  margin-bottom: 0;
  font-size: 1.25rem;
  word-wrap: break-word !important;
}
.text-sub {
  color: #afce38;
  font-size: 1.25rem;
  word-wrap: break-word !important;
}
.modal-body {
  margin: auto;
  text-align: center;
}
</style>
